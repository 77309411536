import { createApp } from 'vue'
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"

import App from './App.vue'
import router from './router'
import { i18n } from 'vue-lang-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faGlobe as fasGlobe } from '@fortawesome/free-solid-svg-icons'
library.add(fasGlobe)

createApp(App)
    .use(i18n)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

