export default {
	lt: {
		name: 'Lietuvių',
		load: () => { return import('./lt.json'); },
	},
	en: {
		name: 'English',
		load: () => { return import('./en.json'); },
	},
	ru: {
		name: 'Русский',
		load: () => { return import('./ru.json'); },
	}
};
