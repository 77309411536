<template>
    <Carousel/>

    <div class="container marketing">
        <div class="row">
            <BubbleFact number='home.bubble-facts[0].number' short='home.bubble-facts[0].title' long='home.bubble-facts[0].text' spacing='25%'/>

            <BubbleFact number ='home.bubble-facts[1].number' short='home.bubble-facts[1].title' long='home.bubble-facts[1].text'/>

            <BubbleFact number='home.bubble-facts[2].number' short='home.bubble-facts[2].title' long='home.bubble-facts[2].text'/>
        </div>
    </div>
    
    <div class="container marketing">
        <Featurette />
    </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import Featurette from "@/components/Featurette.vue";
import BubbleFact from "@/components/BubbleFact.vue";


export default {
  name: 'App',
  components: {
    Carousel,
    Featurette,
    BubbleFact
  }    
}

</script>

<style scoped>
/* MARKETING CONTENT */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}
</style>