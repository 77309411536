<template>
<div class="footer-divider"></div>
<div class="container">
  <footer class="py-3 my-4">
    <p class="text-center text-muted">&copy; {{currentYear}} {{ $t('footer.legal') }}</p>
  </footer>
</div>
</template>

<script>



export default {
  name: 'SiteFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer-divider {
    height: 3rem;

}
</style>
