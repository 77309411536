<template>
<hr class="featurette-divider">

            <div class="row featurette">
                <div class="col-md-7">
                    <h2 class="featurette-heading">{{ $t('home.marketing[0].title') }}</h2>
                    <p class="lead">{{ $t('home.marketing[0].description') }}</p>
                </div>
                <div class="col-md-5">
                    <img src="https://placem.at/places?w=500&h=500&random=127" class="featurette-image img-fluid mx-auto" :alt="$t('home.marketing[0].image-alt')">
                </div>
            </div>
    
            <hr class="featurette-divider">
    
            <div class="row featurette">
                <div class="col-md-7 order-md-2">
                    <h2 class="featurette-heading">{{ $t('home.marketing[1].title') }}</h2>
                    <p class="lead">{{ $t('home.marketing[1].description') }}</p>
                </div>
                <div class="col-md-5 order-md-1">
                    <img src="https://placem.at/places?w=500&h=500&random=128" class="featurette-image img-fluid mx-auto" :alt="$t('home.marketing[1].image-alt')">
                </div>
            </div>
</template>

<script>



export default {
  name: 'Carousel-home',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 40em) {
    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 7rem;
    }
}
</style>
