<template>
    <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="#313c8c"/>
            <text x="25%" y="50%" fill="white" dx=".15em" dy=".35em" font-size="2.5em">{{$t(number)}}</text>
        </svg>
          
        <h2 class="fw-normal">{{$t(short)}}</h2>
        <p>{{$t(long)}}</p>
    </div>
</template>

<script>



export default {
  name: 'BubbleFact',
  props: ['number', 'short', 'long']
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
