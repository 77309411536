<template>
<div id="mainCarousel" class="carousel slide carousel-fade shadow-2-strong" data-bs-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
        <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="0" class="active" aria-current="true"></button>
        <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="1"></button>
        <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="2"></button>        
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="container">
                <div class="carousel-caption">
                    <h1>{{ $t('home.carousel[0].caption-title') }}</h1>
                    <p>{{ $t('home.carousel[0].caption-text') }}</p>
                </div>
            </div>
        </div>

        <div class="carousel-item">
            <div class="container">
                <div class="carousel-caption">
                    <h1>{{ $t('home.carousel[1].caption-title') }}</h1>
                    <p>{{ $t('home.carousel[1].caption-text') }}</p>
                </div>
            </div>
        </div>
        
        <div class="carousel-item">
            <div class="container">
                <div class="carousel-caption">
                    <h1>{{ $t('home.carousel[2].caption-title') }}</h1>
                    <p>{{ $t('home.carousel[2].caption-text') }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Controls -->
    <button class="carousel-control-prev" type="button" data-bs-target="#mainCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Praeitas</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#mainCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Kitas</span>
    </button>   
</div>

</template>

<script>
export default {
  name: 'CarouselGallery',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Bump up size of carousel content */
@media (min-width: 40em) {
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }    
}

#mainCarousel,
.carousel-inner,
.carousel-item,
.carousel-item.active {
    height: 100vh;
    margin-bottom: 4rem;
}

#mainCarousel {
    margin-top: -58.59px;
}


.carousel-item:nth-child(1) {
    background-image: url('https://placem.at/places?w=1920&h=1080&random=124');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;    
}

.carousel-item:nth-child(2) {
    background-image: url('https://placem.at/places?w=1920&h=1080&random=125');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;    
}

.carousel-item:nth-child(3) {
    background-image: url('https://placem.at/places?w=1920&h=1080&random=126');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;    
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    bottom: 3rem;
    z-index: 10;
}
</style>
