<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light" style="z-index:2000;">
    <div class="container">
        <img src="../assets/dimkonas-logo.png" alt="Dimkonas" height="50" class="d-inline-block align-text-top">

        <button class="navbar-toggler" type="button" 
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" 
            @click="visible=!visible"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" :class="!visible?'collapse':''" id="navbarSupportedContent">
            <ul class="navbar-nav d-flex">
                <li class="nav-item">
                    <router-link to="/" class="nav-link" @click="visible=!visible">{{ $t('nav.home') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/about" class="nav-link" @click="visible=!visible">{{ $t('nav.about') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contact" class="nav-link" @click="visible=!visible">{{ $t('nav.contact') }}</router-link>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ $t('language-text')}}
                        <font-awesome-icon icon="fa-solid fa-globe" />
                    </a>
                    <language-switcher v-slot="{ links }" tag="ul" active-class="navbarDropdown" class="dropdown-menu" >
                        <li :class="link.navbarDropdown" v-for="link in links" :key="link.langIndex">
                            <a :href="link.url" @click="visible=!visible" class="dropdown-item" >{{ link.langName }}</a>
                        </li>
                    </language-switcher>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
        visible: false
    }
  },
  methods: {
    switchLanguage(e) {
        window.location = e.targer.value;
    },
    toggleNavbar() {
        this.show = !this.show;
        console.log("Hello");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
